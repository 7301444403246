import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Card, LabelWithValue, Modal, RelativeTime, useModalConfirm, useRequestAbortController } from 'components';
import TimelineHistory from 'components/layout/TimelineHistory';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ServicesApi } from 'src/api';
import { refreshServices } from 'src/features/Services/actions';
import AddServiceForm from 'src/features/Services/components/AddServiceForm';
import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount, getTimeDiff } from 'src/utils/helpers';

import ServiceAttachments from './components/ServiceAttachments';

import styles from './ServiceDetails.module.scss';

const ServiceDetails = (props) => {
  const { isLoading, service } = props;
  const {
    model,
    id,
    producer,
    name,
    serial_number,
    produced_at,
    first_used_at,
    purchased_at,
    next_service_at,
    description,
    is_disabled,
    expenses,
    files
  } = service;

  const router = useHistory();
  const dispatch = useDispatch();

  const [history, setHistory] = useState([]);
  const [historyTotal, setHistoryTotal] = useState(0);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);

  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [attachmentsVisible, setAttachmentsVisible] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [abortController, setNewController] = useRequestAbortController();

  const getHistory = async () => {
    const params = {
      service_id: id,
      page: 1,
      perPage: 100
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsHistoryLoading(true);
      const { data } = await ServicesApi.getServiceHistory(params, signal);
      setHistory(data.data);
      setHistoryTotal(data.total);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsHistoryLoading(false);
    }
  };

  const deleteService = async () => {
    try {
      const { data } = await ServicesApi.deleteService(id);
      dispatch(refreshServices());
      router.push('/services');
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteWholesalerConfirm = () => {
    handleOpenModalConfirm({
      message: `Na pewno chcesz usunąć serwis ${name}?`,
      handleConfirm: deleteService
    });
  };

  const saveHandler = () => {
    setIsVisibleEdit(false);
    dispatch(refreshServices());
  };

  useEffect(() => {
    if (!service.id) return;
    getHistory();
  }, [service]);

  return (
    <Card className={styles.card}>
      <div className={styles.header}>
        <LabelWithValue
          label={'Nazwa'}
          value={<h2>{name}</h2>}
        />
        <div className={styles.buttons}>
          <Button
            label={'Usuń'}
            onClick={() => deleteWholesalerConfirm()}
            gray
          />
          <TimelineHistory
            history={history}
            buttonLabel={'Historia'}
            title={'Historia'}
            bottomMessage={history?.length ? `+ ${historyTotal - history?.length} innych.` : ''}
            isLoading={isHistoryLoading}
            emptyStateType='servicesHistory'
          />
          <Button
            label={'Edytuj'}
            onClick={() => setIsVisibleEdit(true)}
          />
        </div>
      </div>
      <div>
        <LabelWithValue
          label={'Opis'}
          isLoading={isLoading}
          value={<p>{description || '-'}</p>}
        />
      </div>
      <div className={styles.container}>
        <LabelWithValue
          label={'Status'}
          isLoading={isLoading}
          value={
            is_disabled === 1 ? (
              <span className={styles.isDisabled}>Wyłączony</span>
            ) : (
              <span className={styles.isEnabled}>Włączony</span>
            )
          }
        />
        <LabelWithValue
          label={'Producent'}
          value={producer}
          isLoading={isLoading}
        />
        <LabelWithValue
          label={'Model'}
          value={model}
          isLoading={isLoading}
        />
        <LabelWithValue
          label={'Numer seryjny'}
          value={serial_number}
          isLoading={isLoading}
        />
      </div>
      <div className={styles.container}>
        <LabelWithValue
          label={'Data produkcji'}
          value={toDateNumber(produced_at)}
          isLoading={isLoading}
        />
        <LabelWithValue
          label={'Data zakupu'}
          value={toDateNumber(purchased_at)}
          isLoading={isLoading}
        />
        <LabelWithValue
          label={'Data pierwszego użycia'}
          value={toDateNumber(first_used_at)}
          isLoading={isLoading}
        />
        <LabelWithValue
          label={'Następny serwis'}
          labelClasses={styles.label}
          value={next_service_at ? toDateNumber(next_service_at) : '-'}
          isLoading={isLoading}
          dangerValue={getTimeDiff(next_service_at) < 0}
          dangerWhenFalsy
        />
      </div>
      <div className={styles.container}>
        <LabelWithValue
          label={'Załączniki'}
          value={
            <ServiceAttachments
              files={files}
              serviceId={id}
              refreshCallback={saveHandler}
              visible={attachmentsVisible}
              setVisible={setAttachmentsVisible}
            />
          }
          isLoading={isLoading}
        />
        <LabelWithValue
          label={'Łączny koszt zdarzeń'}
          value={getFormattedAmount(expenses)}
          isLoading={isLoading}
        />
      </div>
      <Modal
        visible={isVisibleEdit}
        title={'Edytuj serwis'}
        onClose={() => setIsVisibleEdit(false)}
      >
        <AddServiceForm
          serviceData={service}
          refreshCallback={saveHandler}
          onClose={() => setIsVisibleEdit(false)}
        />
      </Modal>
      {renderedModalConfirm}
    </Card>
  );
};

export default ServiceDetails;
