import React, { forwardRef, useState } from 'react';
import cn from 'classnames';

import { Icon, ReplacementDetails, SaveButton, Tag, useIsAdmin } from 'components';
import ChangeMenu from 'components/layout/changeMenu';
import InfoWidget from 'components/layout/InfoWidget';

import { getFormattedAmount, getSupplierBackgroundColor } from 'src/utils/helpers';

import ChangeOfferPriceForm from './components/ChangeOfferPriceForm';

import style from './SupplierThumbnail.module.scss';

const SupplierThumbnail = forwardRef((props, ref) => {
  const isAdmin = useIsAdmin();
  const { item, children, isDragging, showReplacements, deletePrice, isEditable } = props;

  const [isActive, setIsActive] = useState(false);

  const containerClasses = cn(style.container, {
    [style.isDragging]: isDragging,
    [style.replacement]: item.type === 'replacement',
    [style.notDraggable]: showReplacements
  });

  const replacements = item.replacements.length > 0 ? item.replacements.filter((replacement) => replacement.id !== item.id) : [];

  return (
    <>
      <div
        className={containerClasses}
        ref={ref}
      >
        <div className={style.nameWrapper}>
          <Tag
            value={item.supplier_name || '-'}
            style={{ borderLeft: `5px solid ${item?.color}` || '' }}
            className={style.tag}
          />
          {item?.type === 'replacement' && (
            <ReplacementDetails replacement={item}>
              <InfoWidget place={'top'}>
                <p>
                  Cena dotyczy zamiennika, który może się różnić się od oryginalnego produktu.{' '}
                  <span className={style.underline}>Kliknij aby sprawdzić jego parametry</span>
                </p>
              </InfoWidget>
            </ReplacementDetails>
          )}
        </div>
        <p className={style.price}>
          {isEditable && isAdmin ? (
            <ChangeMenu
              value={getFormattedAmount(item.price)}
              valueWrapperClasses={style.priceEditable}
              isActive={isActive}
              setIsActive={setIsActive}
              width={'350px'}
              icon={
                <Icon
                  name={'edit'}
                  fill={'#888'}
                />
              }
            >
              <ChangeOfferPriceForm
                offerId={item.id}
                currentPrice={item.price}
                onClose={() => setIsActive(false)}
              />
            </ChangeMenu>
          ) : (
            getFormattedAmount(item.price)
          )}
          {!showReplacements && item.replacements.length > 1 && <span className={style.replacementAvailable}>+1</span>}
        </p>
        {children}
        {showReplacements && replacements.length > 0 && (
          <div className={style.replacementsWrapper}>
            {replacements.map((replacement) => {
              return (
                <div
                  key={replacement.id}
                  className={style.replacementItem}
                >
                  <div className={style.nameWrapper}>
                    <Tag
                      value={replacement?.type === 'replacement' ? 'Zamiennik' : 'Oferta na produkt'}
                      className={cn(style.tag, style.tagNested)}
                    />
                    {replacement?.type === 'replacement' && (
                      <ReplacementDetails replacement={replacement}>
                        <InfoWidget place={'top'}>
                          <p>
                            Cena dotyczy zamiennika, który może się różnić się od oryginalnego produktu.{' '}
                            <span className={style.underline}>Kliknij aby sprawdzić jego parametry</span>
                          </p>
                        </InfoWidget>
                      </ReplacementDetails>
                    )}
                  </div>
                  <p className={style.price}>{getFormattedAmount(replacement.price)}</p>
                  <SaveButton
                    type={'delete'}
                    onClick={() => deletePrice(replacement.id)}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
});

export default SupplierThumbnail;
