import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';

import { Accordion, Checkbox, useIsMobile } from 'components';
import ProductStatusExtended from 'components/layout/ProductStatusExtended';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { orderProductTypes, orderStatuses, productStatuses } from 'src/constants/enums';

import OrderListProduct from '../../../OrderListProduct';

import style from '../../OrderCart.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductTable = (props) => {
  const { token, refreshDataCallback, setSelectedProducts, selectedProducts, status, products, hideProductActions } = props;
  const isMobile = useIsMobile(1023);
  const productsSection = useRef();

  const handleSaveQty = async (product, finally_quantity) => {
    const query = { token, products: [{ id: product.id, quantity: finally_quantity }] };
    try {
      await SupplierApi.acceptProductQuantity(query);
      await refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const filteredProducts = products.filter((prod) => [orderStatuses.pending, orderStatuses.processing].includes(prod.status));

  const getIsSelected = (item) => selectedProducts.filter((product) => product.id === item.id)?.length > 0;
  const changeItemSelection = (item) => {
    setSelectedProducts((prev) => (getIsSelected(item) ? prev.filter((product) => product.id !== item.id) : [...prev, item]));
  };
  const selectAll = () => {
    if (selectedProducts?.length === filteredProducts?.length) setSelectedProducts([]);
    else setSelectedProducts(filteredProducts);
  };

  const showCheckbox = useCallback(
    (type, productStatus) =>
      type === orderProductTypes.supplier && [orderStatuses.pending, orderStatuses.processing].includes(productStatus),
    [status]
  );

  const isSelectAllCheckboxVisible = useMemo(
    () => products.filter((prod) => [orderStatuses.pending, orderStatuses.processing].includes(prod.status)).length > 0,
    [products]
  );

  const productContent = (product, type) => (
    <div
      className={classNames(style.productCheckboxWrapper, {
        [style.flex]: !showCheckbox(type, product.status)
      })}
    >
      <ProductStatusExtended
        product={product}
        className={style.tag}
      />
      {showCheckbox(type, product.status) && (
        <Checkbox
          value={getIsSelected(product)}
          onChange={() => changeItemSelection(product)}
        />
      )}
    </div>
  );

  const checkbox = (
    <div className={style.checkboxWrapper}>
      {!isMobile && <p className={style.checkboxText}>Status</p>}
      <Checkbox
        content={isMobile ? 'Zaznacz wszystko' : null}
        value={selectedProducts?.length === filteredProducts?.length}
        onChange={selectAll}
        reverse
      />
    </div>
  );

  const title = (
    <h3
      className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding, {
        [style.sectionTitle]: !hideProductActions
      })}
    >
      Lista produktów
    </h3>
  );

  const descriptionContent = useMemo(() => {
    if (status === orderStatuses.processing) {
      if (
        products.some(
          (prod) => ![productStatuses.completed, productStatuses.sent, productStatuses.cancelled].includes(prod.status)
        )
      ) {
        return 'Zaznacz dane pozycje produktowe lub ich części gotowe do wysyłki. Pozostałe produkty będą nadal oczekiwały na wysyłkę, możesz ją realizować kilkukrotnie.';
      } else {
        return '';
      }
    } else if (status === orderStatuses.completed) {
      return '';
    } else {
      return 'Zaznacz produkty, które jesteś w stanie zrealizować. Pozostałe produkty zostaną anulowane.';
    }
  }, [status, products]);

  const description = <p className={style.description}>{descriptionContent}</p>;

  return (
    <Accordion
      section
      title={title}
      description={!hideProductActions && description}
      withoutGap
    >
      <section
        ref={productsSection}
        className={style.container}
      >
        <div className={classNames(shared.tableHeader, style.header, { [style.withMenu]: !hideProductActions })}>
          <p>Lp.</p>
          <p>Kod hurtownii</p>
          <p>Nazwa</p>
          <p>Producent</p>
          <p>Ilość</p>
          <p>Cena za szt.</p>
          <p>Wartość</p>
          {isSelectAllCheckboxVisible ? checkbox : <p>Status</p>}
        </div>
        {products?.length ? (
          products?.map((product, index) => (
            <OrderListProduct
              displayOver
              index={index + 1}
              status={status}
              key={product.id}
              product={product}
              type={orderProductTypes.supplier}
              isSelected={getIsSelected(product)}
              hideProductActions={hideProductActions}
              refreshDataCallback={refreshDataCallback}
              onQtyChange={(qty) => handleSaveQty(product, qty)}
              onSelectChange={() => changeItemSelection(product)}
            >
              {productContent(product, orderProductTypes.supplier)}
            </OrderListProduct>
          ))
        ) : (
          <div className={shared.tableCategory}>
            <h3 className={shared.tableCategoryTitle}>Brak produktów na liście</h3>
          </div>
        )}
      </section>
    </Accordion>
  );
};

export default ProductTable;
