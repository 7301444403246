import React, { useState } from 'react';

import { CommentModal, Menu, Modal, Tag, useIsAdmin, useModalConfirm, useSearchParam } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ServicesApi } from 'src/api';
import { serviceEventsStatuses } from 'src/constants/enums';
import EventForm from 'src/features/ServicesView/components/EventForm';
import { toDateNumber } from 'src/utils/dateTime';
import { getEventTypeTag, getFormattedAmount, getFormattedPhoneNumber } from 'src/utils/helpers';

import EventAttachments from '../EventThumbnail/components/EventAttachments';

import styles from './EventThumbnail.module.scss';

const EventThumbnail = ({ refreshCallback, event, params }) => {
  const selectedCompany = useSearchParam(params, 'company');

  const { event_date, servicer, id, type, comment, files, status, expenses } = event;

  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [attachmentsVisible, setAttachmentsVisible] = useState(false);
  const [editVisible, setEditVisible] = useState();

  const deleteEvent = async () => {
    try {
      const { data } = await ServicesApi.deleteEvent(id);
      if (refreshCallback) refreshCallback();
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteEventConfirm = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć serwis?',
      handleConfirm: deleteEvent
    });
  };

  const actions = [
    {
      title: 'Załączniki',
      icon: 'search',
      action: () => setAttachmentsVisible(true)
    },
    {
      title: 'Edytuj',
      icon: 'edit',
      action: () => setEditVisible(true),
      color: 'blue'
    },
    {
      title: 'Usuń',
      icon: 'trash',
      action: deleteEventConfirm,
      color: 'red'
    }
  ];

  return (
    <>
      <div className={styles.container}>
        <div>{getEventTypeTag({ type, className: styles.tag })}</div>
        <div>{serviceEventsStatuses[status]}</div>
        <div className={styles.productThumbnail}>
          <p
            className={styles.textOverflow}
            title={servicer?.name}
          >
            {servicer?.name}
          </p>
        </div>
        <div>
          <p
            className={styles.textOverflow}
            title={servicer?.keeper}
          >
            {servicer?.keeper}
          </p>
        </div>
        <div>
          <p
            className={styles.textOverflow}
            title={getFormattedPhoneNumber(servicer?.phone)}
          >
            {getFormattedPhoneNumber(servicer?.phone)}
          </p>
        </div>
        <div>
          <CommentModal
            comment={comment}
            modalTitle={'Powód'}
            emptyState={<Tag value={'Brak komentarza'} />}
          />
        </div>
        <div>{expenses > 0 ? getFormattedAmount(expenses) : '-'}</div>
        <div>
          <EventAttachments
            files={files}
            eventId={event.id}
            refreshCallback={refreshCallback}
            visible={attachmentsVisible}
            setVisible={setAttachmentsVisible}
          />
        </div>
        <div>{event_date ? toDateNumber(event_date) : '-'}</div>
        <div>
          <Menu
            actions={actions}
            className={styles.menu}
          />
        </div>
      </div>
      <Modal
        visible={editVisible}
        title={'Edytuj dane'}
        onClose={() => setEditVisible(false)}
      >
        <EventForm
          eventData={{ ...event, servicer: { label: servicer?.name, value: servicer?.id } }}
          refreshCallback={refreshCallback}
          onClose={() => setEditVisible(false)}
          type={type}
          selectedCompany={selectedCompany}
        />
      </Modal>
      {renderedModalConfirm}
    </>
  );
};

export default EventThumbnail;
