import React from 'react';

import { getFormattedAmount, getNumberWithThousandsSeparator } from 'src/utils/helpers';

import style from './Supplier.module.scss';

const Supplier = ({ index, supplier }) => {
  const { supplier_name, products } = supplier;

  const getAssignedOffersCounter = ({ amount, percentage }) => (
    <div className={style.wrapper}>
      <p>{getNumberWithThousandsSeparator(amount)}</p>
      <p className={style.percentages}>({getFormattedAmount(percentage, '%')})</p>
    </div>
  );

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <p>{index + 1}.</p>
      </div>
      <div className={style.wrapper}>
        <p>{supplier_name}</p>
      </div>
      <div className={style.wrapper}>
        <p>{getNumberWithThousandsSeparator(products?.offered_products)}</p>
      </div>
      <div className={style.wrapper}>
        <p>{getNumberWithThousandsSeparator(products?.offered_replacements)}</p>
      </div>
      {getAssignedOffersCounter(products.assigned.as_first)}
      {getAssignedOffersCounter(products.assigned.as_second)}
      {getAssignedOffersCounter(products.assigned.as_third)}
      <div className={style.wrapper}>
        <p>{getFormattedAmount(products.monthly_expenses)} *</p>
      </div>
    </div>
  );
};

export default Supplier;
