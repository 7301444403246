export const orderProcessSteps = [
  {
    title: 'Lista',
    description: 'Utwórz listę produktów'
  },
  {
    title: 'Twój koszyk',
    description: 'Wybierz oferty'
  },
  {
    title: 'Podsumowanie',
    description: 'Wybierz opcje dostawy i płatności'
  },
  {
    title: 'Złożenie zamówienia',
    description: 'Wysłanie do realizacji'
  }
];

export const cancelledOrderStatusProgress = [
  {
    title: 'Złożono',
    description: 'Zamówienie zostało utworzone'
  },
  {
    title: 'Anulowano',
    description: 'Zamówienie zostało anulowane',
    status: 'error'
  }
];

export const defaultOrderStatusProgress = [
  {
    title: 'Złożono',
    description: 'Zamówienie zostało utworzone'
  },
  {
    title: 'Oczekuje',
    description: 'Oczekuje na potwierdzenie'
  },
  {
    title: 'W realizacji',
    description: 'Dostawcy potwierdzają realizacje zamówień'
  },
  {
    title: 'Zrealizowano',
    description: 'Zamówienie zostało odebrane'
  }
];

export const orderWithAcceptStatusProgress = [
  {
    title: 'Utworzono',
    description: 'Zamówienie zostało stworzone'
  },
  {
    title: 'Akceptacja',
    description: 'Oczekuje na akceptację'
  },
  {
    title: 'Oczekuje',
    description: 'Oczekuje na potwierdzenie'
  },
  {
    title: 'W realizacji',
    description: 'Dostawcy potwierdzają realizacje zamówień'
  },
  {
    title: 'Zrealizowano',
    description: 'Zamówienie zostało odebrane'
  }
];
