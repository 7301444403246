export const ordersNtfTypes = [
  'App\\Notifications\\OrderAccepted',
  'App\\Notifications\\OrderCompleted',
  'App\\Notifications\\OrderCancelledBySupplier',
  'App\\Notifications\\MessageSent',
  'App\\Notifications\\PriceChanged'
];

export const supplierOrdersNtfTypes = [
  'App\\Notifications\\PricingCreated',
  'App\\Notifications\\OrderCreated',
  'App\\Notifications\\OrderCancelled',
  'App\\Notifications\\MessageSentToSupplier'
];

export const valuationsNtfTypes = ['App\\Notifications\\PricingCompleted'];

export const servicesNftTypes = ['App\\Notifications\\ServiceReminder'];
