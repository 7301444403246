import { useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { ReactComponent as DownIcon } from 'src/assets/icons/angle-down.svg';

import style from './Accordion.module.scss';

const preventOpenSelectors = ['.prevent-trigger-accordion', '#modal', '.ant-popover'];

const Accordion = ({
  title,
  description,
  children,
  section,
  defaultWrapped,
  noCollapse,
  yellow,
  extraDeps = [],
  noPadding,
  openOnArrowClick,
  id,
  noGap,
  setWasOpened,
  withActions,
  borderLeftDecorator
}) => {
  const [isContentShowed, setIsContentShowed] = useState(!defaultWrapped);
  const [height, setHeight] = useState('unset');
  const [prevHeight, setPrevHeight] = useState(0);
  const contentRef = useRef(null);
  const childrenWrapperRef = useRef(null);

  const gapSize = section ? 0 : 24;

  const showAnswer = (e) => {
    if (
      noCollapse ||
      (withActions && e.target.classList.contains('prevent-trigger-accordion')) ||
      (withActions && preventOpenSelectors.some((selector) => e.target.closest(selector) !== null))
    )
      return;

    if (setWasOpened) setWasOpened(true);
    setPrevHeight(contentRef.current?.getBoundingClientRect().height + gapSize);
    setIsContentShowed((prev) => !prev);
    setHeight(isContentShowed ? 0 : prevHeight);
  };

  useLayoutEffect(() => {
    if (contentRef.current && !noCollapse) {
      setPrevHeight(contentRef.current?.getBoundingClientRect()?.height + gapSize);
      setHeight(defaultWrapped ? '0' : contentRef.current?.getBoundingClientRect()?.height + gapSize);
    }
  }, []);

  useLayoutEffect(() => {
    if (isContentShowed) {
      setPrevHeight(childrenWrapperRef.current?.getBoundingClientRect()?.height + gapSize);
      setHeight(childrenWrapperRef.current?.getBoundingClientRect()?.height + gapSize);
    } else {
      setPrevHeight(childrenWrapperRef.current?.getBoundingClientRect()?.height + gapSize);
    }
  }, extraDeps);

  const iconStyles = cn(style.arrow, { [style.down]: isContentShowed });
  const boxClasses = cn(style.box, {
    [style.section]: section,
    [style.withActions]: !noCollapse,
    [style.yellow]: yellow,
    [style.noPadding]: noPadding,
    [style.openOnArrowClick]: openOnArrowClick,
    [style.borderLeftDecorator]: borderLeftDecorator
  });
  const titleClasses = cn(style.title, { [style.sectionTitle]: section, [style.openOnArrowClick]: openOnArrowClick });
  const activeTitleClasses = cn(style.activeTitle, {
    [style.sectionTitle]: section,
    [style.noPadding]: noPadding,
    [style.openOnArrowClick]: openOnArrowClick
  });
  const contentClasses = cn(style.content, { [style.withGap]: !section && !noGap });
  const paddingBoxClasses = cn(style.padding, { [style.asCard]: !section });

  if (noCollapse) {
    return (
      <div
        className={boxClasses}
        id={id}
      >
        <div className={titleClasses}>{title}</div>
        <div className={contentClasses}>
          <div className={paddingBoxClasses}>{children}</div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={boxClasses}
      id={id}
    >
      <div
        onClick={openOnArrowClick ? undefined : showAnswer}
        className={activeTitleClasses}
      >
        <div className={style.titleWrapper}>
          {title}
          {description}
        </div>
        <DownIcon
          className={iconStyles}
          onClick={openOnArrowClick ? showAnswer : undefined}
        />
      </div>
      <div
        ref={contentRef}
        className={contentClasses}
        style={{ maxHeight: height }}
      >
        <div
          className={paddingBoxClasses}
          ref={childrenWrapperRef}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
