import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  FilterByRole,
  Modal,
  PageHeader,
  PaginationNav,
  Searchbar,
  SeoHelmet,
  SwitchButton,
  useIsMobile,
  useRequestAbortController,
  useSearchParam
} from 'components';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import addIcon from 'src/assets/icons/add.svg';
import pages from 'src/dictionaries/pages.json';
import UsersTable from 'src/features/Users/components/UsersTable';
import { getCorrectFormOfResultsLabel } from 'src/utils/helpers';
import query from 'src/utils/query';

import AddUser from './components/AddUser';

import style from './Users.module.scss';

const Users = (props) => {
  const params = query(props);
  const isMobile = useIsMobile();
  const usersVersion = useSelector((state) => state.users.version);

  const [itemsQty, setItemsQty] = useState();
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [pageData, setPageData] = useState([]);
  const [showDeactivated, setShowDeactivated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addUserModalVisible, setAddUserModalVisible] = useState();
  const [abortController, setNewController] = useRequestAbortController();

  const queryRole = useSearchParam(params, 'role');
  const sortBy = useSearchParam(params, 'sort_by');
  const currentPage = useSearchParam(params, 'page');
  const querySearch = useSearchParam(params, 'search');
  const sortOrder = useSearchParam(params, 'sort_order');

  useEffect(() => {
    getData();
  }, [currentPage, querySearch, perPage, usersVersion, queryRole, sortBy, sortOrder, showDeactivated]);

  const getData = async () => {
    let error;
    const queryProps = {
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch,
      role: queryRole,
      ...(sortBy && { sort_by: sortBy }),
      ...(sortOrder && { sort_order: sortOrder }),
      ...(showDeactivated && { show_deactivated: 1 })
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await AdminApi.getUsers(queryProps, signal);
      setPageData(data.data);
      setItemsQty(data.total);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
      error = err;
    } finally {
      setIsLoading(error?.message === 'canceled');
    }
  };

  return (
    <>
      <SeoHelmet title={pages.users.title} />
      <PageHeader
        name={pages.users.title}
        wrapOnMobile
        text={isLoading ? headerTextPlaceholder() : getCorrectFormOfResultsLabel(itemsQty)}
      >
        <div className={style.searchWrapper}>
          <SwitchButton
            value={showDeactivated}
            setValue={setShowDeactivated}
            label={'Pokaż nieaktywnych'}
          />
          <Searchbar
            params={params}
            fullWidth={isMobile}
          />
          <FilterByRole params={params} />
          <Button
            label={'Dodaj użytkownika'}
            icon={addIcon}
            onClick={() => setAddUserModalVisible(true)}
            className={style.button}
          />
        </div>
      </PageHeader>
      <div className={style.container}>
        <UsersTable
          list={pageData}
          isLoading={isLoading}
          params={params}
        />
        <PaginationNav
          params={params}
          pagesQty={pageQty}
          setPerPage={setPerPage}
          defaultQty={perPage}
        />
      </div>
      <Modal
        title='Dodaj nowego użytkownika'
        visible={addUserModalVisible}
        onClose={() => setAddUserModalVisible(false)}
      >
        <AddUser onClose={() => setAddUserModalVisible(false)} />
      </Modal>
    </>
  );
};

export default Users;
