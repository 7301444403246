import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import cn from 'classnames';

import { ChangeUnit } from 'components';
import InputWrapper, { getWrapperProps } from 'components/layout/InputWrapper';

import style from './QtySelector.module.scss';

const notAllowedKeys = ['-', '+', 'e', '_', '=', '*', '^', 'ArrowUp', 'ArrowDown'];

const QtySelector = (props) => {
  const {
    setState,
    horizontal = false,
    state = 0,
    suffix = '',
    className,
    wrapperStyle,
    hideArrows,
    onBlur,
    disabled,
    priceInput,
    width,
    percentages,
    unitSelect = false,
    unitState,
    setUnitState,
    units,
    hideValueWhenZero,
    children
  } = props;

  const [value, setValue] = useState(state);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setValue(Number(state));
  }, [state]);

  useEffect(() => {
    if (setState) setState(value);
  }, [value]);

  const onKeyPress = (e) => {
    if (notAllowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const onWheel = (e) => e.target.blur();

  const setQtyValue = (e) => {
    if (/^0\d+$/.test(e.target.value)) {
      setValue(Number(e.target.value).toString());
      if (setState) setState(Number(e.target.value).toString());
    } else {
      setValue(e.target.value.toString());
      if (setState) setState(e.target.value.toString());
    }
  };

  const handleFocus = () => {
    if (+value === 0) {
      setValue('');
    }
  };

  const handleBlur = (e) => {
    if (onBlur) onBlur(e);
    if (value === '') setValue('0');
    if (percentages) setValue(Math.min(Number(e.target.value), 100));
    if (priceInput) {
      setValue(Number(e.target.value).toFixed(2));
    }
  };

  useEffect(() => {
    if (priceInput) {
      setValue(Number(value).toFixed(2));
    }
  }, []);

  const wrapperProps = getWrapperProps({
    ...props,
    value: state
  });

  return (
    <InputWrapper
      {...wrapperProps}
      value={state}
      setShowError={setShowError}
      showError={showError}
    >
      {children}
      <div
        style={width ? { width } : {}}
        className={classNames(
          style.quantity,
          {
            [style.horizontal]: horizontal,
            [style.suffix]: suffix !== '' || unitSelect,
            [style.disabled]: disabled,
            [style.error]: showError,
            [style.suffixWithArrow]: !hideArrows && suffix
          },
          wrapperStyle
        )}
      >
        <input
          data-hj-allow
          type='number'
          value={value}
          onChange={setQtyValue}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyDown={onKeyPress}
          onWheel={onWheel}
          className={cn(
            style.input,
            {
              [style.suffix]: !!suffix || unitSelect,
              [style.horizontal]: horizontal,
              [style.disabled]: disabled,
              [style.hideValueWhenZero]: hideValueWhenZero && +value === 0
            },
            className
          )}
          disabled={disabled}
        />
        {!!suffix && <span className={cn(style.suffixText, { [style.disabled]: disabled })}>{suffix}</span>}
        {unitSelect && (
          <ChangeUnit
            disabled={disabled}
            onSave={setUnitState}
            currentUnit={unitState}
            units={units}
          />
        )}
        {!hideArrows && (
          <>
            <button
              className={classNames(style.minus, {
                [style.button]: !horizontal,
                [style.ball]: horizontal,
                [style.disabled]: value === 0
              })}
              onClick={() => setValue((prev) => (prev >= 1 ? prev - 1 : 0))}
              title={'Zmniejsz o 1'}
              tabIndex={-1}
              type='button'
            />
            <button
              className={classNames(style.plus, {
                [style.button]: !horizontal,
                [style.ball]: horizontal
              })}
              onClick={() => setValue((prev) => Number(prev) + 1)}
              title={'Zwiększ o 1'}
              tabIndex={-1}
              type='button'
            />
          </>
        )}
      </div>
    </InputWrapper>
  );
};

export default QtySelector;
