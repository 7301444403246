import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge } from 'antd';
import classNames from 'classnames';

import { Checkbox, CommentModal, CustomTooltip, InfoWidget, Menu, Tag, useIsMobile, VisibilityCallback } from 'components';
import useReadNotification from 'components/hooks/useReadNotification';
import ChangePrice from 'components/layout/changeMenu/ChangePrice';
import ChangeQuantity from 'components/layout/changeMenu/ChangeQuantity';
import ProductStatusExtended from 'components/layout/ProductStatusExtended';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import { ReactComponent as WarehouseIcon } from 'src/assets/icons/newSidebar/warehouse-check.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/up-arrow.svg';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import replacementThumbnail from 'src/assets/img/replacement-thumbnail.png';
import { NotificationTypes, orderProductTypes, orderStatuses } from 'src/constants/enums';
import { getTimeAgo, toDateNumber, toTime } from 'src/utils/dateTime';
import { getFormattedAmount, getProductStatusTag } from 'src/utils/helpers';

import style from './OrderProductThumbnail.module.scss';

const OrderProductThumbnail = (props) => {
  const {
    product,
    isSelected,
    onSelectChange,
    type,
    hideStatusColumn,
    onQtyChange,
    addedToWarehouse,
    qtyWithoutMargin,
    index,
    priceChangeData,
    onPriceChange,
    notifications,
    supplierId
  } = props;

  const {
    name,
    producer,
    unit_name,
    qty,
    price,
    price_for_one,
    comment,
    thumbnail,
    image,
    status,
    type: productType,
    product_id,
    received_quantity
  } = product || {};

  const { prevPrice, date } = priceChangeData || {};
  const priceWrapperRef = useRef(false);
  const productNotification = useMemo(
    () => notifications?.find((ntf) => ntf.data?.product_id === product_id),
    [notifications, product]
  );

  const showStatusBadge = useMemo(() => {
    const orderNtf = notifications?.find(
      (ntf) =>
        (ntf?.data?.supplier_id === supplierId &&
          [NotificationTypes.OrderAccepted, NotificationTypes.OrderCancelledBySupplier].includes(ntf.type)) ||
        []
    );
    return (
      orderNtf?.data?.cancelledProducts?.find((id) => id === product_id) ||
      orderNtf?.type === NotificationTypes.OrderCancelledBySupplier
    );
  }, [notifications, supplierId]);

  const isMobile = useIsMobile(hideStatusColumn ? 767 : 991);
  const [isQtyActive, setIsQtyActive] = useState(false);
  const [isPriceActive, setIsPriceActive] = useState(false);
  const [readNotificationHandler] = useReadNotification(productNotification);

  const newQuantityProducts = useSelector((state) => state.valuationView.newQuantityProducts);
  const newQuantity = useMemo(() => {
    return newQuantityProducts.find((item) => item.id === product.id)?.new_quantity;
  }, [newQuantityProducts]);

  const wasQtyChange = useMemo(() => newQuantity && +newQuantity !== qty, [newQuantity, qty]);

  const actions = [
    {
      title: 'Zmień ilość w zamówieniu',
      icon: 'edit',
      action: () => setTimeout(() => setIsQtyActive(true), 50)
    }
  ];

  const warehouseInfo = (
    <CustomTooltip title={'Dodano do magazynu'}>
      <WarehouseIcon className={style.tick} />
    </CustomTooltip>
  );

  const priceTooltipContent =
    +prevPrice > 0 ? (
      <div className={style.tooltip}>
        <p>Poprzednia cena: {getFormattedAmount(prevPrice)}</p>
        {!!date && (
          <p>
            Zmieniono {getTimeAgo(date).date}
            <br />({toDateNumber(date)}, {toTime(date)})
          </p>
        )}
      </div>
    ) : null;

  const priceChangeIndicator =
    +prevPrice && +prevPrice !== +price_for_one ? (
      <ArrowIcon className={+price_for_one > +prevPrice ? style.up : style.down} />
    ) : null;

  return (
    <div
      id={productNotification ? `suborder-product-${productNotification.data?.product_id}` : undefined}
      className={classNames(style.container, {
        [style.hideStatusColumn]: hideStatusColumn,
        [style.cancelled]: status === 'CANCELLED',
        [style.replacement]: productType === 'replacement',
        [style.offer]: productType === 'offer'
      })}
    >
      <p>{index}.</p>
      <div
        className={classNames(style.wrapper, style.productName, {
          [style.hideStatusColumn]: hideStatusColumn
        })}
      >
        <ProductThumbnailImage
          thumbnail={type === 'replacement' ? replacementThumbnail : thumbnail}
          image={type === 'replacement' ? null : image}
          title={name}
          id={product_id}
        />
        <p className={classNames(style.text, style.trim)}>
          {type === 'replacement' && (
            <span className={classNames(style.info, style.replacement)}>
              <WarningIcon />
              Zamiennik
            </span>
          )}
          {type === 'offer' && <span className={classNames(style.info, style.offer)}>Oferta dostawcy</span>}
          {name}
        </p>
      </div>
      <div className={classNames(style.wrapper, style.producer)}>
        {isMobile && <p className={style.label}>Producent: </p>}
        <p className={style.text}>{producer}</p>
      </div>
      <div className={classNames(style.wrapper, style.qty)}>
        {isMobile && <p className={style.label}>Ilość: </p>}
        {onQtyChange ? (
          <ChangeQuantity
            wasQtyChange={wasQtyChange}
            quantity={newQuantity || qty}
            unit={unit_name}
            setQuantity={onQtyChange}
            isActive={isQtyActive}
            setIsActive={setIsQtyActive}
            withoutMargin={qtyWithoutMargin}
            displayOver
          />
        ) : (
          <p className={style.text}>{`${newQuantity || qty} ${unit_name ? unit_name : ''}`}</p>
        )}
        {received_quantity > 0 && received_quantity < qty && (
          <InfoWidget
            place={'bottom'}
            className={style.infoQty}
          >
            <p>
              Odebrano {received_quantity} / {qty} {unit_name ? unit_name : ''}
            </p>
          </InfoWidget>
        )}
      </div>
      <div
        className={style.wrapper}
        ref={priceWrapperRef}
      >
        {isMobile && <p className={style.label}>Cena za szt.: </p>}
        {onPriceChange ? (
          <>
            <ChangePrice
              price={price_for_one}
              setPrice={onPriceChange}
              isActive={isPriceActive}
              setIsActive={setIsPriceActive}
              displayOver
            />
            <CustomTooltip title={priceTooltipContent}>{priceChangeIndicator}</CustomTooltip>
          </>
        ) : (
          <CustomTooltip title={priceTooltipContent}>
            <p className={style.text}>
              {getFormattedAmount(price_for_one)}
              {priceChangeIndicator}
            </p>
          </CustomTooltip>
        )}
        <VisibilityCallback
          notification={productNotification}
          elementRef={priceWrapperRef}
          callback={readNotificationHandler}
        />
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.label}>Wartość: </p>}
        <p className={style.text}>{getFormattedAmount(price)}</p>
      </div>
      <div className={classNames(style.wrapper, style.comment)}>
        {isMobile && <p className={style.label}>Komentarz do produktu: </p>}
        <CommentModal
          comment={comment}
          modalTitle={'Komentarz do produktu'}
          title={name}
          className={style.commentModal}
        />
      </div>
      {!hideStatusColumn && (
        <div className={style.checkboxWrapper}>
          <ProductStatusExtended
            product={product}
            className={style.tag}
          />
          {!!addedToWarehouse && warehouseInfo}
          <Badge
            dot={!!showStatusBadge}
            offset={[-10, -10]}
          />
          {type === orderProductTypes.supplier && status === orderStatuses.pending && (
            <Checkbox
              value={isSelected}
              onChange={onSelectChange}
              wrapperStyle={style.checkbox}
            />
          )}
        </div>
      )}
      {!!onQtyChange && (
        <Menu
          actions={actions}
          className={style.menu}
          displayOver
        />
      )}
    </div>
  );
};

export default OrderProductThumbnail;
