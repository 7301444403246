import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Button, Modal, OptionSwitch } from 'components';

import { ReactComponent as PlusIcon } from 'src/assets/icons/plus-blue.svg';

import ProductDetails from '../SuppliersList/components/ProductDetails';
import Product from './components/Product';
import Replacement from './components/Replacement';

import style from './AddNewPrice.module.scss';

const AddNewPrice = ({ productId, selectedCompany, asButton, gray, product, asLink }) => {
  const [visible, setVisible] = useState(false);
  const [productForm, setProductForm] = useState(true);
  const isDefinedView = useSelector((state) => state.cartAndCatalog.isDefinedView);

  const id = productId || product?.product_id;
  const handleOpen = () => setVisible(true);
  const handleClose = () => setVisible(false);

  const modal = (
    <Modal
      visible={visible}
      onClose={() => setVisible(false)}
      title={id ? 'Dodaj ofertę do produktu' : 'Dodaj produkt'}
    >
      {!!product && (
        <div className={style.productWrapper}>
          <ProductDetails
            product={product}
            tiny
          />
        </div>
      )}
      <div className={style.switchWrapper}>
        <OptionSwitch
          defaultOption={productForm}
          onChange={setProductForm}
          falseLabel={'Oferta na zamiennik'}
          trueLabel={'Oferta na produkt'}
        />
      </div>
      {productForm ? (
        <Product
          productId={id}
          selectedCompany={selectedCompany}
          closeModal={handleClose}
        />
      ) : (
        <Replacement
          productId={id}
          selectedCompany={selectedCompany}
          closeModal={handleClose}
        />
      )}
    </Modal>
  );

  if (asButton) {
    return (
      <>
        <Button
          label={id ? 'Dodaj ofertę' : 'Dodaj nowy produkt'}
          onClick={handleOpen}
          gray={gray}
        />
        {modal}
      </>
    );
  }

  if (asLink) {
    return (
      <>
        <a onClick={handleOpen}>{id ? 'Dodaj ofertę' : 'Dodaj nowy produkt'}</a>
        {modal}
      </>
    );
  }

  return (
    <>
      <button
        className={cn(style.button, { [style.fullView]: !isDefinedView })}
        onClick={handleOpen}
        title={'Dodaj ofertę do produktu'}
      >
        <div className={style.container}>
          <PlusIcon className={style.icon} />
          <p className={style.label}>{id ? '' : 'Dodaj nową ofertę'}</p>
        </div>
      </button>
      {modal}
    </>
  );
};

export default AddNewPrice;
