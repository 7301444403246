import React, { useState } from 'react';

import { LoadingIndicator, QtySelector } from 'components';
import useAutoSave from 'components/hooks/useAutoSave';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';

import style from './SetRefPrice.module.scss';

const SetRefPrice = ({ price, uid, productId }) => {
  const [refPrice, setRefPrice] = useState(price ?? undefined);
  const [isSaveError, setIsSaveError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const saveRefPrice = async () => {
    const data = {
      uid,
      id: productId,
      ref_price: refPrice
    };

    if (!data.ref_price) return;

    try {
      setIsSaveError(false);
      setIsLoading(true);
      await AdminApi.editListProduct(data);
    } catch (err) {
      notifyApiError(err);
      setIsSaveError(true);
      price && setRefPrice(Number(price).toFixed(2));
    } finally {
      setIsLoading(false);
    }
  };

  useAutoSave({
    first: +refPrice,
    second: +price,
    saveHandler: saveRefPrice,
    dependency: [refPrice]
  });

  return (
    <div className={style.wrapper}>
      <QtySelector
        id={`${productId}-price`}
        state={refPrice}
        setState={setRefPrice}
        suffix={'zł'}
        width={120}
        priceInput
        hideArrows
        hideValueWhenZero
      />
      <LoadingIndicator
        isLoading={isLoading}
        hasError={isSaveError}
        aboveInput
      />
    </div>
  );
};

export default SetRefPrice;
